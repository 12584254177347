import TetherComponent from "react-tether";

import {
  Icon,
  clsx,
  UIContext,
  SidebarState,
  Logo,
  Button,
  getHashCode,
  classNames,
} from "@alpacahq/ui";

import React, {
  useState,
  useMemo,
  useContext,
  useEffect,
  useCallback,
} from "react";

import { SidebarItem } from "./SidebarItem";

export interface SidebarAccount {
  name: string;
  number: string;
}

export interface SidebarAccountSwitcherProps {
  darkLogo?: boolean;
  hideParens?: boolean;
  accounts: SidebarAccount[];
  currentAccount: SidebarAccount | null;
  handleSwitchAccount: (account: SidebarAccount) => void;
}

export const SidebarAccountSwitcher: React.FC<SidebarAccountSwitcherProps> = ({
  darkLogo,
  hideParens,
  accounts,
  currentAccount,
  handleSwitchAccount,
}) => {
  const { sidebarState, setIsSidebarBlurred } = useContext(UIContext);

  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpacity, setDropdownOpacity] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(false);

  // short-form for checking if the sidebar is collapsed
  const isCollapsed = sidebarState === SidebarState.COLLAPSED;

  // filter out the current account from the list of accounts
  const filteredAccounts = useMemo(() => {
    return accounts.filter(
      (account) => account.number !== currentAccount?.number
    );
  }, [accounts, currentAccount]);

  useEffect(() => {
    // blur the sidebar when the dropdown is open to focus on the dropdown
    setIsSidebarBlurred(isOpen);
  }, [isOpen]);

  useEffect(() => {
    // close the dropdown when clicking outside of it
    // granted it may be a click on the dropdown itself, but
    // we want to close it anyway when you click an item
    const handleClickOutside = () => setIsOpen(false);
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // due to positioning issues with the dropdown, we need to force a re-render after a delay
  // the dropdown will not get cut-off from parent scroll containers this way
  const toggleOpen = (event: any) => {
    event.stopPropagation();

    setIsOpen(!isOpen);
    setDropdownOpacity(0);

    if (!isOpen) {
      setTimeout(() => {
        setForceUpdate(true);
        setDropdownOpacity(1);
      }, 100);
    }
  };

  const handleUpdate = useCallback(() => {
    // force a re-render after the first update
    if (forceUpdate) {
      setForceUpdate(false);
    }
  }, [forceUpdate]);

  return (
    <TetherComponent
      // @ts-ignore for some reason the types are not correct
      attachment="top left"
      // @ts-ignore for some reason the types are not correct
      targetAttachment="bottom left"
      // @ts-ignore for some reason the types are not correct
      offset="-12px 0"
      onUpdate={handleUpdate}
      renderTarget={(ref) => (
        <Button
          variant="ghost"
          ref={ref}
          onClick={toggleOpen}
          className={clsx(
            // base styles
            "flex w-full cursor-pointer rounded-lg items-center space-x-1 h-10 px-0 py-0",
            // collapsed styles
            !isCollapsed ? "justify-between" : "justify-center bg-transparent"
          )}
        >
          <div className="flex w-full items-center space-x-2">
            <Logo
              className={classNames("h-9 w-9", darkLogo && "text-gray-900")}
            />
            {!isCollapsed && (
              <div className="w-full text-left text-base">
                <div className="font-semibold text-gray-900">
                  {currentAccount?.name}
                </div>
                <div className="font-medium text-gray-500">
                  {currentAccount?.number}
                </div>
              </div>
            )}
          </div>
          {!isCollapsed && <Icon name="ChevronUpDown" />}
        </Button>
      )}
      renderElement={(ref) =>
        isOpen && (
          <div
            ref={ref}
            style={{ opacity: dropdownOpacity }}
            className="z-50 mx-[4px] min-w-[186.4px] space-y-0.5 rounded-md border border-gray-200 bg-white p-1"
          >
            {filteredAccounts.length > 0 ? (
              filteredAccounts.map((account) => (
                <SidebarItem
                  unresponsive
                  label={
                    <>
                      {account.name}&nbsp;
                      {
                        <span className="w-full">
                          {hideParens ? account.number : `(${account.number})`}
                        </span>
                      }
                    </>
                  }
                  key={getHashCode(account)}
                  onClick={() => handleSwitchAccount(account)}
                  {...account}
                />
              ))
            ) : (
              <div className="p-2.5 text-base font-medium text-gray-500">
                No other accounts
              </div>
            )}
          </div>
        )
      }
    />
  );
};
