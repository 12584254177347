import useRequest, { QueryOptions } from "./useRequest";

import { useState } from "react";
import { QueryObserverResult } from "react-query";
import { Account, getAccount } from "../rest/account";

type UseGetAccount = {
  account?: Account;
  isAuthenticated: boolean | undefined;
  refetch: () => Promise<QueryObserverResult<Account>>;
};

export const useGetAccount = (
  key: any | any[] = "",
  // todo: revisit this type, we need to include product for all account-dynamic hooks
  options?: QueryOptions<Account> & { product?: string }
): UseGetAccount => {
  const [isAuthenticated, setAuthed] = useState<boolean | undefined>();

  const {
    data: account,
    isError,
    isSuccess,
    refetch,
  } = useRequest(
    ["account", key, options?.product],
    () => getAccount({ product: options?.product || "live" }),
    {
      ...options,
      refetchInterval: 10000,
      select: (data) => {
        // does not rely on onSuccess and onError being called
        if (!!data && !isAuthenticated) {
          setAuthed(true);
        }

        return data;
      },
      onSuccess: (data) => {
        setAuthed(true);
        options?.onSuccess?.(data);
      },
      onError: () => {
        setAuthed(false);
        options?.onError?.({} as any);
      },
    }
  );

  if (isError || (isSuccess && !account?.id)) {
    // redirect should occur at router level
    // AuthService.signOut();
    // history.push("/login");
  }

  return { account, refetch, isAuthenticated };
};
