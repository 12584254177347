import styled from "@emotion/styled";
import AmplitudeProvider from "src/AmplitudeProvider";
import TopBar from "./topbar";

import {
  useGetAccount,
  useGetAccountDetails,
  useGetBillingOverview,
  useGetElitePerks,
} from "src/v2/api/hooks";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Link, SidebarState, UIContext } from "@alpacahq/ui";
import { Box, Progress } from "@chakra-ui/react";
import { version } from "../../../../package.json";
import { UIPage } from "../../../../src/local_libraries/@alpacahq:ui-deprecated";
import { isNotFullActiveAccount } from "../../../components/LayoutComponents/Menu/MenuLeft/menuData";
import { inSupportedLocation } from "../../../utils/crypto";
import {
  featureFlagManager,
  isFeatureAccessible,
  setFlag,
  useFlag,
} from "../../../v2/helpers/flags";
import { AuthService } from "../../api/auth";
import { useGetMargin } from "../../api/hooks/useGetMargin";
import { useGetPaperAccounts } from "../../api/hooks/useGetPaperAccounts";
import { PaperBanner } from "../../pages/dashboard/home/paperBanner";
import { Path } from "../../path";
import { isPaperOrOnboarding } from "../../utils";
import { Sidebar, SidebarProps } from "../Sidebar/Sidebar";

export interface PageProps {
  children: React.ReactNode;
  noHeader?: boolean;
  disableAppMenu?: boolean;
  product: string;
}

const TEMPORARY_UI_PAGES = [
  {
    path: "/paper/dashboard/orders",
    title: "Orders",
    subtitle: "Your open and closed orders.",
  },
];

export const FUNDING_FEATURE_FLAG = "funding_feature_flag";

export const FUNDING_FEATURE_FLAG_PERCENTAGE = 100;

export const FDIC_SWEEP_FEATURE_FLAG = "fdic_sweep_feature_flag";

export const FDIC_SWEEP_FEATURE_FLAG_PERCENTAGE = 100;

export const heights: Record<string, string | any> = {
  sidebar: "70px",
  topbar: "91.6667px",
  apply: (value: string) =>
    value.replace(/{(.*?)}/g, (match, key) => heights[key]),
};

const Container = styled(Box)`
  .page--content {
    margin-left: auto;
    margin-right: auto;
  }

  .sticky {
    position: sticky;
    top: 0;
  }
`;

export const Page = (props: PageProps) => {
  const v2 = useFlag("uix-v2");

  const { sidebarState } = useContext(UIContext);

  const [product, setProduct] = useState(props.product);

  const isDebugMode =
    new URLSearchParams(window?.location?.search ?? "")?.get("debug") ?? null;

  if (isDebugMode !== null) {
    setFlag("debug", isDebugMode === "true");
  }

  featureFlagManager(FUNDING_FEATURE_FLAG, FUNDING_FEATURE_FLAG_PERCENTAGE);
  featureFlagManager(
    FDIC_SWEEP_FEATURE_FLAG,
    FDIC_SWEEP_FEATURE_FLAG_PERCENTAGE
  );

  useEffect(() => {
    if (product === "dynamic") {
      setProduct(window.localStorage.getItem("product") || "paper");
    }
  }, [product]);

  const isPaper = product === "paper";

  // we are doing this until the pages are migrated to v2
  const temporaryUIPage = TEMPORARY_UI_PAGES.find((path) =>
    window.location.pathname.includes(path.path)
  );

  const { noHeader, children, disableAppMenu } = props;
  const { account, isAuthenticated } = useGetAccount();
  const { paper_accounts } = useGetPaperAccounts("paper", account?.id);
  const { billing } = useGetBillingOverview("billing", {
    enabled: isAuthenticated,
  });
  const { details } = useGetAccountDetails("details", account?.id, {
    enabled: !!account?.id,
  });
  const { perks } = useGetElitePerks("elite-perks", account?.id ?? "", {
    enabled: !!account?.id && !isPaperOrOnboarding(account),
  });

  const isSubscribed =
    (billing?.lifetimeSubCount || 0) > 0 || perks?.market_data;
  const cryptoTier = account?.crypto_tier ?? 0;

  // percentage of tier completion, given 8 tiers
  const cryptoTierProgress = (cryptoTier / 8) * 100;

  const canTransferCrypto =
    inSupportedLocation(details) && !isNotFullActiveAccount({ account });

  const onSwitchUIClick = useCallback(() => {
    setFlag("uix-v2", !v2);

    // send amplitude event
    AmplitudeProvider.dispatch("dropdown_switch_to_legacy_button_clicked");

    // refresh the page
    window.location.reload();
  }, [v2]);

  // lol, why do we even support multiple paper accounts?
  const paper = paper_accounts?.[0];

  // ridiculous hack to get margin account number for paper account
  // for some reason it isn't returned in the paper_accounts endpoint
  const { margin } = useGetMargin(
    "margin-paper",
    paper?.paper_account_id,
    true,
    {
      enabled: !!paper?.paper_account_id,
      staleTime: 10000,
      refetchInterval: 10000,
    }
  );

  // memoize the accounts list, so we don't have to recompute every render
  const accounts = useMemo(() => {
    // if the user is not logged in, return an empty array
    if (!account || margin?.account_number === undefined) {
      return [];
    }

    const list = [
      { name: "Paper", number: margin?.account_number },
      { name: "Live", number: account?.account_number },
    ];

    // should we return only paper account?
    if (isPaperOrOnboarding(account)) {
      return [list[0]];
    }

    return list;
  }, [account, margin]);

  const currentAccount = useMemo(() => {
    if (!accounts.length) {
      return {
        name: "Guest",
        number: "no-account",
      };
    }

    if (product === "live") {
      return accounts[1];
    }

    return accounts[0];
  }, [accounts, product]);

  const sidebarProps: SidebarProps = useMemo(
    () => ({
      handleSwitchAccount: () => {
        // redirect user based on product
        // no need to check if the account is paper or not
        window.location.href = Path.format(Path.ROUTE_DASHBOARD, {
          product: isPaper ? "live" : "paper",
        });
      },
      accounts,
      currentAccount,
      settings: [
        {
          label: "Profile",
          leftIcon: "Identification",
          href: Path.ROUTE_ALPACA_PROFILE,
        },
        {
          label: "Bug Report",
          leftIcon: "BugAnt",
          newTab: true,
          href: Path.URL_BUG_REPORT,
        },
        {
          label: "Log Out",
          leftIcon: "LockClosed",
          onClick: AuthService.signOut,
        },
      ],
      items: [
        {
          label: "Home",
          leftIcon: "Home",
          href: Path.format(Path.ROUTE_DASHBOARD, { product }),
        },
        {
          label: "Account",
          leftIcon: "RectangleStack",
          subItems: [
            {
              label: "Positions",
              leftIcon: "ChartPie",
              href: Path.ROUTE_POSITIONS,
            },
            {
              label: "Orders",
              leftIcon: "ClipboardDocumentList",
              href: Path.format(Path.ROUTE_ORDERS, { product }),
            },
            {
              label: "Activities",
              leftIcon: "QueueList",
              href: Path.format(Path.ROUTE_ACCOUNT_ACTIVITIES, { product }),
            },
            {
              label: "Balances",
              leftIcon: "TableCells",
              href: Path.format(Path.ROUTE_BALANCES, { product }),
            },
            {
              label: "Configure",
              leftIcon: "AdjustmentsVertical",
              href: Path.ROUTE_CONFIGURATION,
            },
            !isPaper && {
              label: "Documents",
              leftIcon: "DocumentDuplicate",
              href: Path.ROUTE_DOCUMENTS,
            },
          ],
        },
        {
          label: "Alpaca Connect",
          leftIcon: "Cloud",
          href: Path.ROUTE_ALPACA_CONNECT,
        },
        {
          label: "Plans & Features",
          leftIcon: "Heart",
          href: Path.ROUTE_PLANS_AND_FEATURES,
          subItems: [
            !isPaper && {
              label: "Trading Plan",
              leftIcon: "ClipboardDocumentList",
              href: Path.ROUTE_ALPACA_TRADING_PLAN,
            },
            !details?.is_professional && {
              label: "Market Data",
              leftIcon: "PresentationChartLine",
              href: isSubscribed
                ? Path.ROUTE_USER_SUBSCRIPTION
                : Path.ROUTE_USER_SUBSCRIPTION_NEW,
            },
            !isPaper &&
              canTransferCrypto && {
                label: "Crypto Fee",
                leftIcon: "ChartBarSquare",
                href: Path.format(Path.ROUTE_ALPACA_PROFILE, {
                  tab: "crypto-fees",
                }),
              },
          ],
        },
        !isPaper && {
          label: `Funds${canTransferCrypto ? " & Wallet" : ""}`,
          leftIcon: "Banknotes",
          subItems: isFeatureAccessible(FUNDING_FEATURE_FLAG)
            ? [
                {
                  label: "Transfer",
                  leftIcon: "BuildingLibrary",
                  href: Path.format(Path.ROUTE_FUNDING, { product }),
                },
                {
                  label: "History",
                  leftIcon: "ArchiveBox",
                  href: Path.format(Path.ROUTE_FUNDING_HISTORY, { product }),
                },
                canTransferCrypto && {
                  label: "Crypto Transfers",
                  leftIcon: "CurrencyDollar",
                  href: Path.format(Path.ROUTE_CRYPTO_TRANSFERS, { product }),
                },
              ]
            : [
                {
                  label: "Banking",
                  leftIcon: "BuildingLibrary",
                  href: Path.format(Path.ROUTE_BANKING, { product }),
                },
                canTransferCrypto && {
                  label: "Crypto Transfers",
                  leftIcon: "CurrencyDollar",
                  href: Path.format(Path.ROUTE_CRYPTO_TRANSFERS, { product }),
                },
              ],
        },
        {
          label: "API",
          leftIcon: "CommandLine",
          href: Path.URL_DOCS,
          newTab: true,
        },
        {
          label: "Community",
          leftIcon: "ChatBubbleLeftRight",
          subItems: [
            {
              label: "Slack",
              leftIcon: "Hashtag",
              href: Path.URL_SLACK,
              newTab: true,
            },
            {
              label: "Forum",
              leftIcon: "Users",
              href: Path.URL_FORUM,
              newTab: true,
            },
          ],
        },
        {
          label: "Support",
          leftIcon: "Lifebuoy",
          href: Path.URL_SUPPORT,
          newTab: true,
        },
        {
          label: "Legal",
          leftIcon: "Scale",
          href: Path.URL_DISCLOSURES,
          newTab: true,
        },
      ],
    }),
    [
      account,
      billing,
      currentAccount,
      canTransferCrypto,
      cryptoTierProgress,
      details,
      inSupportedLocation,
      isNotFullActiveAccount,
      isPaper,
      isSubscribed,
      onSwitchUIClick,
      product,
      sidebarState,
      v2,
      version,
    ]
  );

  useEffect(() => {
    // if defined pass account and details to AmplitudeProvider
    if (details && account) {
      AmplitudeProvider.setAccount({ account, details });
    }
  }, [account, details]);

  return (
    <>
      <Container>
        <Box h="100%" w="100%" display="flex" id="page-wrapper">
          {!disableAppMenu && sidebarState !== SidebarState.HIDDEN && (
            <Sidebar {...sidebarProps}>
              {sidebarState === SidebarState.EXPANDED &&
                !isPaper &&
                canTransferCrypto && (
                  <>
                    <div className="bg-white border border-b-3 border-warm-gray-200 rounded-md p-2.5">
                      <Link
                        className="w-full font-medium text-gray-800 dark:text-gray-200 rounded-md focus:border-yellow-50 focus:outline-none focus:ring-yellow-50"
                        to={Path.format(Path.ROUTE_ALPACA_PROFILE, {
                          tab: "crypto-fees",
                        })}
                      >
                        <div className="flex justify-between items-center mb-2">
                          Crypto Fee Rate
                          <label className="text-xs bg-gray-200 rounded p-1 dark:bg-blue-400 dark:text-gray-800">
                            Level&nbsp; {cryptoTier}
                          </label>
                        </div>
                        <Progress
                          bg="gray.50"
                          id="progress"
                          size="sm"
                          hasStripe
                          isAnimated
                          rounded="md"
                          value={cryptoTierProgress}
                        />
                      </Link>
                    </div>
                  </>
                )}
            </Sidebar>
          )}
          <Box
            h="100vh"
            marginLeft="auto"
            overflowY="scroll"
            flexGrow={1}
            className="page--content max-w-6xl"
          >
            {!noHeader &&
              // todo: temporary until subscription flow is migrated to v2
              !["agreement", "subscription", "subscribe"].some(
                (s) =>
                  window.location.href.includes(s) &&
                  // @todo temporary conflict with agreements profile tab & options opt-in agreement
                  !(
                    window.location.href.includes("agreements") ||
                    window.location.href.includes("options-agreement")
                  )
              ) && (
                <>
                  {product === "paper" && <PaperBanner account={account} />}
                  <TopBar product={product} />
                </>
              )}
            <Box>
              <Box id="__anchor" />
              {!!temporaryUIPage ? (
                <UIPage
                  title={temporaryUIPage.title}
                  subtitle={temporaryUIPage.subtitle}
                  banner={
                    product === "paper" && <PaperBanner account={account} />
                  }
                >
                  {children}
                </UIPage>
              ) : (
                children
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Page;
