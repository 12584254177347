import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  REDUCER,
  submit,
  refreshUserAttributes,
  resendAttributeVerification,
} from "reducers/profile";
import {
  MFAMethods,
  enableMFA,
  disableMFA,
  currentMFAMethod,
  setupTOTP,
  verifyTOTPToken,
} from "reducers/auth/mfa";
import Auth from "@aws-amplify/auth";
import { getCurrentAuthenticatedUser } from "src/utils";
import { Form, Input, Button, Row, Modal } from "antd";

import { ProfileMFA, MFAModalContents, MFAChallenge } from "./common";
import api from "src/api";
import { Card, CardBody, CardFooter, CardHeader } from "src/components/common";

const Wrapper = styled.div`
  a,
  p,
  div {
    font-family: Carnas !important;
  }
  a {
    text-decoration: underline;
    color: #377dff;
    font-weight: 600;
  }
  .ant-form-item-label label {
    font-size: 14px;
    padding-bottom: 0px;
  }
`;

const FormItem = Form.Item;

const mapStateToProps = (state) => {
  return {
    isSubmitForm: state.app.submitForms[REDUCER],
    userState: state.auth.userState,
    mfaMethod: state.mfa.method,
    TOTPQRCode: state.mfa.TOTPQRCode,
    TOTPSetupVerificationError: state.mfa.TOTPSetupVerificationError,
    phoneNumberVerificationError: state.profile.phoneNumberVerificationError,
    phoneNumberToVerify: state.profile.phoneNumberToVerify,
  };
};

@connect(mapStateToProps)
@Form.create()
class EditProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mfaExpand: true,
      passwordExpand: false,
      mfaModalVisible: false,
      smsModalVisible: false,
      disablingMfa: false,
      challengeModalVisible: false,
    };
  }

  static defaultProps = {};

  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(currentMFAMethod());
  };

  toggleExpand(field) {
    this.setState({ [field]: !this.state[field] });
  }

  onSubmit = (isSubmitForm) => (event) => {
    event.preventDefault();
    const { form, dispatch } = this.props;
    if (!isSubmitForm) {
      form.validateFields((error, values) => {
        if (!error) {
          // Ensure mask values are not included
          if (values.phone_number) {
            values.phone_number = values.phone_number
              .replace(" ", "")
              .replace(/-/g, "");
          }
          dispatch(submit(values));
          dispatch(refreshUserAttributes());
          this.toggleExpand("passwordExpand");
          form.resetFields();
        }
      });
    }
  };

  /**
   * Handles the verification for the user's phone number.
   * Phone numbers saved to user attributes in Cognito must be verified.
   */
  onSubmitPhoneNumberVerification = (code, activating) => {
    const { dispatch } = this.props;
    Auth.verifyCurrentUserAttributeSubmit("phone_number", code).then(() => {
      if (activating === null) {
        this.toggleExpand("passwordExpand");
        this.setState({ challengeModalVisible: false });
        return;
      }

      if (activating) {
        dispatch(enableMFA());
      } else {
        dispatch(disableMFA());
      }
    });
  };

  handlePasswordToggle = () => {
    const { mfaMethod, dispatch } = this.props;

    // if MFA is disabled, just open - otherwise present a verification challenge
    if (mfaMethod === "NOMFA") {
      this.setState({ passwordExpand: !this.state.passwordExpand });
      return;
    }
    if (mfaMethod === "SMS_MFA") {
      dispatch(resendAttributeVerification());
    }

    this.setState({ challengeModalVisible: true });
  };
  onSubmitPhoneNumber = (phoneNumber) => {
    const { dispatch } = this.props;
    getCurrentAuthenticatedUser().then((user) => {
      api.ownerDetails
        .patch(user.attributes["custom:account_id"], {
          phone_number: phoneNumber.replace(/-/g, ""),
        })
        .then(() => {
          dispatch(resendAttributeVerification("phone_number"));
        });
    });
  };

  /**
   * Handles the verification for the user's TOTP setup.
   * Cognito will not enable TOTP MFA until the user has confirmed that
   * they have a TOTP app generating a synchronized token.
   */
  onSubmitTOTPTokenVerification = (token) => {
    let { dispatch } = this.props;
    dispatch(verifyTOTPToken(token));
    dispatch(refreshUserAttributes());
  };

  completeTOTPChallenge = (code, activating) => {
    let { dispatch } = this.props;
    getCurrentAuthenticatedUser().then((user) => {
      Auth.verifyTotpToken(user, code)
        .then(() => {
          if (activating === null) {
            this.toggleExpand("passwordExpand");
            this.setState({ challengeModalVisible: false });
            return;
          }

          dispatch(disableMFA());
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  render() {
    const {
      form,
      isSubmitForm,
      userState,
      mfaMethod,
      TOTPQRCode,
      TOTPSetupVerificationError,
      dispatch,
      phoneNumberVerificationError,
    } = this.props;
    const phoneNumber =
      (userState.attributes && userState.attributes.phone_number) || "";
    const phoneNumberVerified =
      (userState.attributes && userState.attributes.phone_number_verified) ||
      "";
    const mfaMethodDisplayName =
      (MFAMethods[mfaMethod] && MFAMethods[mfaMethod].displayName) || "";
    const cognitoId = userState.attributes && userState.attributes["sub"];
    // Easily adjust which MFA methods are available to the user, since both were built.
    // ie. ['SMS', 'TOTP']
    const validMFAMethods = ["SMS", "TOTP"];

    const onCancelMFA = () => {
      this.setState({ smsModalVisible: false, disablingMfa: false });
    };

    return (
      <Wrapper>
        <Card className="fade-in-up fade-in-up-2">
          <CardHeader>Two-Factor Authentication Method</CardHeader>
          <CardBody>
            <Form
              layout="vertical"
              hideRequiredMark
              onSubmit={this.onSubmit(isSubmitForm)}
            >
              {this.state.mfaExpand && (
                <div>
                  {validMFAMethods.includes("TOTP") && (
                    <Row style={{ marginBottom: "1rem" }} w>
                      <ProfileMFA
                        type="totp"
                        title="Authenticator"
                        securityLevel="Secure (Recommended)"
                        onClick={() => {
                          if (
                            mfaMethod !==
                            MFAMethods["SOFTWARE_TOKEN_MFA"].method
                          ) {
                            dispatch(setupTOTP());
                          } else {
                            this.setState({ disablingMfa: true });
                          }
                          this.setState({ mfaModalVisible: true });
                        }}
                        disabled={
                          mfaMethod !== MFAMethods["SOFTWARE_TOKEN_MFA"].method
                        }
                        current={mfaMethod}
                      />
                    </Row>
                  )}

                  {validMFAMethods.includes("SMS") && (
                    <Row>
                      <ProfileMFA
                        type="sms"
                        title="SMS code"
                        securityLevel="Moderately Secure"
                        onClick={() => {
                          if (mfaMethod === MFAMethods["SMS_MFA"].method) {
                            this.setState({ disablingMfa: true });
                            dispatch(resendAttributeVerification());
                          }
                          this.setState({ smsModalVisible: true });
                        }}
                        disabled={mfaMethod !== MFAMethods["SMS_MFA"].method}
                        current={mfaMethod}
                      />
                    </Row>
                  )}
                </div>
              )}
            </Form>
            <Modal
              visible={this.state.mfaModalVisible}
              footer={null}
              width="450px"
              onCancel={onCancelMFA}
            >
              <div style={{ padding: "20px" }}>
                <MFAModalContents
                  totpQRCode={TOTPQRCode}
                  activating={!this.state.disablingMfa}
                  type={"SOFTWARE_TOKEN_MFA"}
                  onVerify={
                    this.state.disablingMfa
                      ? this.completeTOTPChallenge
                      : this.onSubmitTOTPTokenVerification
                  }
                  onCancel={onCancelMFA}
                  current={mfaMethod}
                  error={TOTPSetupVerificationError}
                  cognitoId={cognitoId}
                />
              </div>
            </Modal>
            <Modal
              visible={this.state.smsModalVisible}
              footer={null}
              onCancel={onCancelMFA}
            >
              <div style={{ padding: "20px" }}>
                <MFAModalContents
                  activating={!this.state.disablingMfa}
                  type={"SMS_MFA"}
                  onCancel={onCancelMFA}
                  onVerify={this.onSubmitPhoneNumberVerification}
                  onSubmit={this.onSubmitPhoneNumber}
                  current={mfaMethod}
                  error={phoneNumberVerificationError}
                  cognitoId={cognitoId}
                />
              </div>
            </Modal>
            <Modal
              visible={this.state.challengeModalVisible}
              footer={null}
              onCancel={() => this.setState({ challengeModalVisible: false })}
            >
              <div style={{ padding: "20px" }}>
                <MFAChallenge
                  activating={null}
                  onBack={null}
                  onVerify={
                    mfaMethod === "SMS_MFA"
                      ? this.onSubmitPhoneNumberVerification
                      : this.completeTOTPChallenge
                  }
                  type={mfaMethod}
                  error={
                    mfaMethod === "SMS_MFA"
                      ? phoneNumberVerificationError
                      : TOTPSetupVerificationError
                  }
                  title={"Change your password"}
                />
              </div>
            </Modal>
          </CardBody>
        </Card>
        <Card
          className="fade-in-up fade-in-up-4"
          style={{ marginTop: "1.5rem" }}
        >
          <CardHeader>Password</CardHeader>
          <Form
            layout="vertical"
            hideRequiredMark
            onSubmit={this.onSubmit(isSubmitForm)}
          >
            <CardBody>
              {!this.state.passwordExpand && (
                <Button onClick={this.handlePasswordToggle}>Change</Button>
              )}
              {this.state.passwordExpand && [
                <FormItem
                  label="Old Password"
                  style={{ marginBottom: "0.5rem", fontWeight: "500" }}
                >
                  {form.getFieldDecorator("old_password", {
                    rules: [
                      {
                        pattern: /^.{12,}$/,
                        message: [
                          <ul
                            key="old_password"
                            className="signup_password_requirements"
                          >
                            <li>
                              Password must contain at least 12 characters
                            </li>
                          </ul>,
                        ],
                      },
                    ],
                  })(
                    <Input
                      size="default"
                      name="old_password"
                      type="password"
                      placeholder="****************"
                    />
                  )}
                </FormItem>,
                <FormItem
                  label="New Password"
                  style={{
                    marginBottom: "0px",
                    paddingBottom: "0px",
                    fontWeight: "500",
                  }}
                >
                  {form.getFieldDecorator("new_password", {
                    rules: [
                      {
                        pattern: /^.{12,}$/,
                        message: [
                          <ul
                            key="new_password"
                            className="signup_password_requirements"
                          >
                            <li>
                              Password must contain at least 12 characters
                            </li>
                          </ul>,
                        ],
                      },
                    ],
                  })(
                    <Input
                      size="default"
                      name="new_password"
                      type="password"
                      placeholder="****************"
                    />
                  )}
                </FormItem>,
              ]}
            </CardBody>
            {this.state.passwordExpand && (
              <CardFooter
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  onClick={() => this.setState({ passwordExpand: false })}
                >
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary">
                  Update
                </Button>
              </CardFooter>
            )}
          </Form>
        </Card>
      </Wrapper>
    );
  }
}

// todo: this is temporary until we rewrite in v2, i dont have time :)
@connect(mapStateToProps)
@Form.create()
export class EditProfileFormClone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mfaExpand: true,
      passwordExpand: false,
      mfaModalVisible: false,
      smsModalVisible: false,
      disablingMfa: false,
      challengeModalVisible: false,
    };
  }

  static defaultProps = {};

  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(currentMFAMethod());
  };

  toggleExpand(field) {
    this.setState({ [field]: !this.state[field] });
  }

  onSubmit = (isSubmitForm) => (event) => {
    event.preventDefault();
    const { form, dispatch } = this.props;
    if (!isSubmitForm) {
      form.validateFields((error, values) => {
        if (!error) {
          // Ensure mask values are not included
          if (values.phone_number) {
            values.phone_number = values.phone_number
              .replace(" ", "")
              .replace(/-/g, "");
          }
          dispatch(submit(values));
          dispatch(refreshUserAttributes());
          this.toggleExpand("passwordExpand");
          form.resetFields();
        }
      });
    }
  };

  /**
   * Handles the verification for the user's phone number.
   * Phone numbers saved to user attributes in Cognito must be verified.
   */
  onSubmitPhoneNumberVerification = (code, activating) => {
    const { dispatch } = this.props;
    Auth.verifyCurrentUserAttributeSubmit("phone_number", code).then(() => {
      if (activating === null) {
        this.toggleExpand("passwordExpand");
        this.setState({ challengeModalVisible: false });
        return;
      }

      if (activating) {
        dispatch(enableMFA());
      } else {
        dispatch(disableMFA());
      }
    });
  };

  handlePasswordToggle = () => {
    const { mfaMethod, dispatch } = this.props;

    // if MFA is disabled, just open - otherwise present a verification challenge
    if (mfaMethod === "NOMFA") {
      this.setState({ passwordExpand: !this.state.passwordExpand });
      return;
    }
    if (mfaMethod === "SMS_MFA") {
      dispatch(resendAttributeVerification());
    }

    this.setState({ challengeModalVisible: true });
  };
  onSubmitPhoneNumber = (phoneNumber) => {
    const { dispatch } = this.props;
    getCurrentAuthenticatedUser().then((user) => {
      api.ownerDetails
        .patch(user.attributes["custom:account_id"], {
          phone_number: phoneNumber.replace(/-/g, ""),
        })
        .then(() => {
          dispatch(resendAttributeVerification("phone_number"));
        });
    });
  };

  /**
   * Handles the verification for the user's TOTP setup.
   * Cognito will not enable TOTP MFA until the user has confirmed that
   * they have a TOTP app generating a synchronized token.
   */
  onSubmitTOTPTokenVerification = (token) => {
    let { dispatch } = this.props;
    dispatch(verifyTOTPToken(token));
    dispatch(refreshUserAttributes());
  };

  completeTOTPChallenge = (code, activating) => {
    let { dispatch } = this.props;
    getCurrentAuthenticatedUser().then((user) => {
      Auth.verifyTotpToken(user, code)
        .then(() => {
          if (activating === null) {
            this.toggleExpand("passwordExpand");
            this.setState({ challengeModalVisible: false });
            return;
          }

          dispatch(disableMFA());
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  render() {
    const {
      form,
      isSubmitForm,
      userState,
      mfaMethod,
      TOTPQRCode,
      TOTPSetupVerificationError,
      dispatch,
      phoneNumberVerificationError,
    } = this.props;
    const phoneNumber =
      (userState.attributes && userState.attributes.phone_number) || "";
    const phoneNumberVerified =
      (userState.attributes && userState.attributes.phone_number_verified) ||
      "";
    const mfaMethodDisplayName =
      (MFAMethods[mfaMethod] && MFAMethods[mfaMethod].displayName) || "";
    const cognitoId = userState.attributes && userState.attributes["sub"];
    // Easily adjust which MFA methods are available to the user, since both were built.
    // ie. ['SMS', 'TOTP']
    const validMFAMethods = ["SMS", "TOTP"];

    const onCancelMFA = () => {
      this.setState({
        mfaModalVisible: false,
        smsModalVisible: false,
        disablingMfa: false,
      });
      // refresh if we haven't just disabled MFA
      if (mfaMethod !== "NOMFA") {
        // not ideal but we are replacing this entire app soon, so...
        window.location.reload();
      }
    };

    return (
      <Wrapper>
        <Form
          layout="vertical"
          hideRequiredMark
          onSubmit={this.onSubmit(isSubmitForm)}
        >
          {this.state.mfaExpand && (
            <div>
              {validMFAMethods.includes("TOTP") && (
                <Row style={{ marginBottom: "1rem" }}>
                  <ProfileMFA
                    type="totp"
                    title="Authenticator"
                    securityLevel="Secure (Recommended)"
                    onClick={() => {
                      if (
                        mfaMethod !== MFAMethods["SOFTWARE_TOKEN_MFA"].method
                      ) {
                        dispatch(setupTOTP());
                      } else {
                        this.setState({ disablingMfa: true });
                      }
                      this.setState({ mfaModalVisible: true });
                    }}
                    disabled={
                      mfaMethod !== MFAMethods["SOFTWARE_TOKEN_MFA"].method
                    }
                    current={mfaMethod}
                  />
                </Row>
              )}

              {validMFAMethods.includes("SMS") && (
                <Row>
                  <ProfileMFA
                    type="sms"
                    title="SMS code"
                    securityLevel="Moderately Secure"
                    onClick={() => {
                      if (mfaMethod === MFAMethods["SMS_MFA"].method) {
                        this.setState({ disablingMfa: true });
                        dispatch(resendAttributeVerification());
                      }
                      this.setState({ smsModalVisible: true });
                    }}
                    disabled={mfaMethod !== MFAMethods["SMS_MFA"].method}
                    current={mfaMethod}
                  />
                </Row>
              )}
            </div>
          )}
        </Form>
        <Modal
          visible={this.state.mfaModalVisible}
          footer={null}
          width="450px"
          onCancel={onCancelMFA}
        >
          <div style={{ padding: "20px" }}>
            <MFAModalContents
              totpQRCode={TOTPQRCode}
              activating={!this.state.disablingMfa}
              type={"SOFTWARE_TOKEN_MFA"}
              onVerify={
                this.state.disablingMfa
                  ? this.completeTOTPChallenge
                  : this.onSubmitTOTPTokenVerification
              }
              onCancel={onCancelMFA}
              current={mfaMethod}
              error={TOTPSetupVerificationError}
              cognitoId={cognitoId}
            />
          </div>
        </Modal>
        <Modal
          visible={this.state.smsModalVisible}
          footer={null}
          onCancel={onCancelMFA}
        >
          <div style={{ padding: "20px" }}>
            <MFAModalContents
              activating={!this.state.disablingMfa}
              type={"SMS_MFA"}
              onCancel={onCancelMFA}
              onVerify={this.onSubmitPhoneNumberVerification}
              onSubmit={this.onSubmitPhoneNumber}
              current={mfaMethod}
              error={phoneNumberVerificationError}
              cognitoId={cognitoId}
            />
          </div>
        </Modal>
        <Modal
          visible={this.state.challengeModalVisible}
          footer={null}
          onCancel={() => this.setState({ challengeModalVisible: false })}
        >
          <div style={{ padding: "20px" }}>
            <MFAChallenge
              activating={null}
              onBack={null}
              onVerify={
                mfaMethod === "SMS_MFA"
                  ? this.onSubmitPhoneNumberVerification
                  : this.completeTOTPChallenge
              }
              type={mfaMethod}
              error={
                mfaMethod === "SMS_MFA"
                  ? phoneNumberVerificationError
                  : TOTPSetupVerificationError
              }
              title={"Change your password"}
            />
          </div>
        </Modal>
      </Wrapper>
    );
  }
}

export default EditProfileForm;
